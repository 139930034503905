import { HttpMethods, HttpStatusCodes, makeRequest } from '@benefits/http-client';

import { BASE_URL, endpoints } from './endpoints';

export type UserInfoResponse = {
  id: string;
  name: string;
  email: string;
  roles: Array<Roles>;
  hasCpfRegistered: boolean;
};

export type Roles = 'financial-operator' | 'admin' | 'card-operator' | 'manager';

const getUserInfo = async () => {
  const response = await makeRequest<UserInfoResponse>({
    baseURL: BASE_URL,
    method: HttpMethods.GET,
    url: `${endpoints.v1.user.me}`,
  });

  return response?.status === HttpStatusCodes.OK && response?.body;
};

export { getUserInfo };
